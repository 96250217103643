angular.module('comcast.common.communication').service('RestrictFileService', ['NotificationService', function (NotificationService) {
  var restrictedVideoTypes = ['m4v', 'wmv'];

  function checkRestrictedTypes(fileName, fromSpotFileUpload) {
    var uploadArray = fileName.split('.');
    var extension = uploadArray[uploadArray.length - 1];
    var invalidFileList = ["exe", "bat", "sh", "cmd", "com", "scr", "msi", "reg", "app", "apk", "ipa", "deb", "bin", "iso", "dmg", "torrent", "ace", "arj", "arc"];
    var specialCharPattern = /[<>"=]/;

    if (restrictedVideoTypes.indexOf(extension) > -1 || invalidFileList.indexOf(extension.toLowerCase()) > -1 && fromSpotFileUpload) {
      NotificationService.showNotificationToast('Invalid file type selected, please try a different file type.');
      return false;
    } else if (specialCharPattern.test(uploadArray[0])) {
      NotificationService.showNotificationToast('ERROR: Invalid file name.The file name should not contain any of the following special characters <>"=');
      return false;
    }

    return true;
  }

  return {
    checkRestrictedTypes: checkRestrictedTypes
  };
}]);